import axios from 'axios';
import get from 'lodash.get';

import { API } from '../api/types';
import { apiError, apiStart, apiSuccess } from '../api/actions';

const apiMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  if (action.type !== API) return;

  const {
    url,
    method,
    data,
    onSuccess,
    onFailure,
    label,
    headers,
    timeout,
    softTimeout,
    softTimeoutFn,
    softTimeoutMsg,
  } = action.payload;
  const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';

  // axios default configs
  axios.defaults.baseURL = process.env.REACT_APP_API_URL || '';
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  const state = getState();
  const token = get(state, 'auth.token');
  const tokenType = get(state, 'auth.tokenType');

  if (tokenType) {
    axios.defaults.headers.common['type-token'] = tokenType;
  }
  if (token) {
    axios.defaults.headers.common['quickmove-token'] = token;
  }

  if (label) {
    dispatch(apiStart(label));
  }

  let requestIsDone = false;

  if (softTimeoutFn) {
    setTimeout(() => {
      if (requestIsDone === false) {
        softTimeoutFn(softTimeoutMsg);
      }
    }, softTimeout);
  }

  axios
    .request({
      url,
      method,
      headers,
      [dataOrParams]: data,
      timeout,
    })
    .then(({ status, data }) => {
      requestIsDone = true;
      if (status >= 400) {
        const error = new Error(
          `Error: Request failed with status code ${status}`
        );
        onFailure(error);
        dispatch(apiError(label, error));
      }
 else {
        onSuccess(data);
        dispatch(apiSuccess(label, data));
      }
    })
    .catch(error => {
      requestIsDone = true;
      dispatch(apiError(label, error));
      onFailure(error);
    });
};

export default apiMiddleware;
