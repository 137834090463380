import {
  OVERVIEW_END_DATE_CHANGED,
  OVERVIEW_START_DATE_CHANGED,
  SELECTED_COMPANY_CHANGED,
  SELECTED_CURRENCY_CHANGED,
} from './types';

export const overviewStartDateChanged = startDate => ({
  type: OVERVIEW_START_DATE_CHANGED,
  payload: startDate,
});

export const overviewEndDateChanged = endDate => ({
  type: OVERVIEW_END_DATE_CHANGED,
  payload: endDate,
});

export const selectedCompanyChanged = companyId => ({
  type: SELECTED_COMPANY_CHANGED,
  payload: companyId,
});

export const selectedCurrencyChanged = currency => ({
  type: SELECTED_CURRENCY_CHANGED,
  payload: currency,
});
