import get from 'lodash.get';
import { API_START, API_SUCCESS, API_ERROR, API_RESET } from './types';

export default function(state = {}, action) {
  const label = get(action, 'payload.label');
  switch (action.type) {
    case API_START:
      if (action.payload) {
        return {
          ...state,
          [action.payload]: { isLoading: true },
        };
      }
      return state;
    case API_SUCCESS:
      if (label) {
        return {
          ...state,
          [label]: { isLoading: false, data: action.payload.data },
        };
      }
      return state;
    case API_ERROR:
      if (label) {
        return {
          ...state,
          [label]: { isLoading: false, error: action.payload.error },
        };
      }
      return state;
    case API_RESET:
      if (label) {
        return {
          ...state,
          [label]: { isLoading: false, data: null, error: null },
        };
      }
      return state;
    default:
      return state;
  }
}
