import {
  OVERVIEW_START_DATE_CHANGED,
  OVERVIEW_END_DATE_CHANGED,
  SELECTED_COMPANY_CHANGED,
  SELECTED_CURRENCY_CHANGED,
} from './types';

const dateNow = new Date();

const INITIAL_STATE = {
  selectedCompanyId: null,
  selectedCurrency: null,
  overviewStartDate: new Date(new Date(dateNow.setMonth(0)).setDate(1)),
  overviewEndDate: new Date(),
};

const kpiReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OVERVIEW_START_DATE_CHANGED:
      return {
        ...state,
        overviewStartDate: action.payload,
      };
    case OVERVIEW_END_DATE_CHANGED:
      return {
        ...state,
        overviewEndDate: action.payload,
      };
    case SELECTED_COMPANY_CHANGED:
      return {
        ...state,
        selectedCompanyId: action.payload,
      };
    case SELECTED_CURRENCY_CHANGED:
      return {
        ...state,
        selectedCurrency: action.payload,
      };
    default:
      return state;
  }
};

export default kpiReducer;
