import { combineReducers } from 'redux';
import apiReducer from 'redux/api';
import kpiReducer from 'scenes/KPI/redux';
import authReducer from 'redux/auth';

const rootReducer = combineReducers({
  api: apiReducer,
  kpi: kpiReducer,
  auth: authReducer,
});

export default rootReducer;
