import { API_START, API_SUCCESS, API_ERROR } from './types';

export const apiStart = label => ({
  type: API_START,
  payload: label,
});

export const apiSuccess = (label, data) => ({
  type: API_SUCCESS,
  payload: { label, data },
});

export const apiError = (label, error) => ({
  type: API_ERROR,
  payload: { label, error },
});
