import { INIT_TOKEN_FROM_QUERY_PARAM } from './types';

const INITIAL_STATE = {
  token: null,
  tokenType: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INIT_TOKEN_FROM_QUERY_PARAM:
      return {
        ...state,
        token: action.payload.token,
        tokenType: action.payload.tokenType,
      };
    default:
      return state;
  }
};

export default authReducer;
