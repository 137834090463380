import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-GB',
    debug: false,
    load: ['currentOnly'],
    preload: ['fr-Fr', 'en-GB'],

    // ns: ['common'],
    // defaultNS: 'common',

    interpolation: {
      escapeValue: false,
    },

    backend: {
      loadPath: `${process.env.REACT_APP_API_URL}locales/{{lng}}/{{ns}}.json`,
      addPath: 'locales/add/{{lng}}/{{ns}}',
    },
  });

export default i18n;
