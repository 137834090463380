import { API, API_RESET } from './types';
import reducer from './reducer';

export function apiAction({
  url = '',
  method = 'GET',
  data = null,
  accessToken = null,
  onSuccess = () => {},
  onFailure = () => {},
  label = '',
  headersOverride = null,
  timeout = null,
  softTimeout = null,
  softTimeoutFn = null,
  softTimeoutMsg = null,
}) {
  return {
    type: API,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onFailure,
      label,
      headersOverride,
      timeout,
      softTimeout,
      softTimeoutFn,
      softTimeoutMsg,
    },
  };
}

export function apiReset({ label }) {
  return {
    type: API_RESET,
    payload: { label },
  };
}

export default reducer;
