const OVERVIEW_START_DATE_CHANGED = 'OVERVIEW_START_DATE_CHANGED';
const OVERVIEW_END_DATE_CHANGED = 'OVERVIEW_END_DATE_CHANGED';

const SELECTED_COMPANY_CHANGED = 'SELECTED_COMPANY_CHANGED';

const SELECTED_CURRENCY_CHANGED = 'SELECTED_CURRENCY_CHANGED';

export {
  OVERVIEW_START_DATE_CHANGED,
  OVERVIEW_END_DATE_CHANGED,
  SELECTED_COMPANY_CHANGED,
  SELECTED_CURRENCY_CHANGED,
};
