export default {
  colors: {
    primary: '#8f96ff',
    primaryLight: '#a9afff',
    primaryDark: '#757eff',
    accent: '#2fed9b',
    accentLight: '#45ffb3',
    grey: '#666666',
    lightGrey: '#9f9f9f',
    black: '#100916',
    white: '#ffffff',
    error: '#FF0000',
    inputBorder: '#e6e2e2',
    inputText: '#7c7c7c',
    kpi: '#7EC6B0',
    kpiDark: '#68A995',
  },
  fonts: {
    sans: 'Open Sans',
    mono: 'Open Sans',
  },
  fontSizes: {
    xSmall: '11px',
    small: '12px',
    medium: '13px',
    button: '15px',
    large: '20px',
    title: '18px',
  },
  charts: {
    familyComposition: {
      colors: ['#757eff', '#87888A', '#12dd8f', '#000000'],
    },
    mobility: {
      othersColor: '#E5E5E5',
      colors: [
        '#757eff',
        '#565AE8',
        '#6BD0FF',
        '#7EC6B0',
        '#6CFF5E',
        '#D1EB2F',
        '#EFF429',
        '#FFB84D',
        '#FF644D',
        '#BB2C16',
        '#FF0833',
        '#D31DF0',
        '#a9afff',
        '#3C00EB',
        '#01AAFF',
        '#66FFD0',
        '#CBFF66',
        '#FFFB66',
        '#BF762F',
        '#FF84C4',
      ],
    },
    servicesCost: {
      colors: ['#6BD0FF', '#565AE8', '#7EC6B0', '#757eff'],
    },
    savingsAchieved: {
      colors: ['#9F9F9F', '#7EC6B0'],
    },
  },
};
